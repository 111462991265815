import React, { useState } from "react";
import { TYPO } from "styles/Typo";

//buttonText : string
//type : line | button (string)
//icon:boolean
//children :
// isFull?: boolean;

function Tabs({ children, ...props }: any) {
  const ChildTabs = () => {
    const filteredChildren = React.Children.toArray(children).filter(
      (child) => !!child
    );

    return (
      <div
        className={`flex ${
          !props?.isFull ? "overflow-x-scroll w-full" : "w-full"
        } ${props?.type === "line" ? `px-4 gap-2` : `px-5 gap-1`} scroll`}
      >
        {filteredChildren.map((child, index) =>
          React.cloneElement(child as React.ReactElement, {
            key: index,
            isFull: props?.isFull,
          })
        )}
      </div>
    );
  };

  if (props?.type === "line") {
    return (
      <div
        className={`w-full bg-white flex gap-4 relative  border-b border-gray-100 common-tab `}
      >
        <ChildTabs />
        {props?.buttonText && (
          <div className={`${TYPO.TYPO7_MD} text-gray50 whitespace-nowrap`}>
            {props?.buttonText}
          </div>
        )}
        {/* {!props?.isFull && (
          <div
            className="absolute w-[30px] h-10 top-0 right-0"
            style={{
              background:
                "linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.50) 74.01%, rgba(255, 255, 255, 0.00) 99.99%, rgba(255, 255, 255, 0.00) 100%)",
            }}
          ></div>
        )} */}
      </div>
    );
  } else {
    //fill
    return (
      <div className="w-full relative flex items-center justify-between shrink common-tab">
        <div className="shrink overflow-auto relative scroll w-full">
          <ChildTabs />
          {!props?.isFull && (
            <div
              className="absolute w-[30px] h-full top-0 right-0"
              style={{
                background:
                  "linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.50) 74.01%, rgba(255, 255, 255, 0.00) 99.99%, rgba(255, 255, 255, 0.00) 100%)",
              }}
            ></div>
          )}
        </div>

        {props?.buttonText && (
          <div
            className={`${TYPO.TYPO7_MD} text-gray50 pr-5 whitespace-nowrap shrink-0`}
          >
            {props?.buttonText}
          </div>
        )}
      </div>
    );
  }
}

export default Tabs;
