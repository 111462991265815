import { TYPO } from "styles/Typo";

function Text({
  ticker,
  item,
  price,
  percent,
}: {
  ticker?: string;
  item: string;
  price?: any;
  percent?: string;
}) {
  return (
    <>
      {ticker && (
        <span
          className={`w-full text-neutral-400 text-sm font-medium leading-snug whitespace-pre-wrap break-word`}
        >
          {ticker}
        </span>
      )}
      <span
        className={`w-full text-gray80 ${TYPO.TYPO5_1_SB} !text-[17px]  whitespace-pre-wrap break-word`}
      >
        {item}
      </span>
      <div className="justify-start items-center gap-1 inline-flex whitespace-pre-wrap break-word w-full">
        {price && (
          <span
            className={`text-gray50 ${TYPO.TYPO7_1_MD} !text-[14px] whitespace-pre-wrap break-word`}
          >
            {typeof price === "string" ? price : price.toLocaleString()}
          </span>
        )}
        {(percent || String(Number(percent)) === "0") && (
          <span
            className={`${
              Number(percent) > 0
                ? "text-rose-500"
                : Number(percent) === 0
                ? "text-gray60"
                : "text-blue-500"
            } text-sm font-medium leading-snug overflow-hidden truncate`}
          >
            {Number(percent) > 0 ? "+" : ""}
            {Number(percent) === 0 ? "0.0" : Number(percent)}%
          </span>
        )}
      </div>
    </>
  );
}

export default Text;
