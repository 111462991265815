import { useSession } from "next-auth/react";
import useSWR from "swr";
import { fetcher } from "./fetcher";
import axios from "axios";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/nextjs";



const useInterest = () => {
    const { data: session, status, update }: any = useSession();
    const {
        data: userInfo,
        isValidating,
        mutate,
    } = useSWR(
        session ? `/api/auth/user?session=${session.user.email}` : null,
        fetcher,
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
        }
    );
    // console.log("userInfo!!!", userInfo);
    const fetcherPost = (url: string) =>
        axios
            .post(url, { interest: userInfo?.user?.interest })
            .then((res) => res.data);
    const {
        data: interestedAssets,
        mutate: interestMutate,
        isValidating: interestValid,
    } = useSWR(session?.user && userInfo?.user?.interest ? "/api/interest" : null, fetcherPost, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });
    // console.log("userInfo.user.interest.length", userInfo?.user?.interest);
    return [session, update, userInfo, mutate, interestedAssets, interestMutate, interestValid];
}

//SD
export const handleSDInterest = async ({ session, update, detailData, router, isInterested, setInterestToastVisible, setInterestErrorToastVisible }: any) => {
    if (!session || !session.user) {
        router.replace("/login");
        return false;
    }
    const enteredInput = {
        interest: {
            name: encodeURIComponent(detailData.ITEM_ENG_NM),
            krName: encodeURIComponent(detailData.ITEM_KR_NM),
            ticker: detailData.ITEM_CD_DL,
        },
        action: isInterested ? "remove" : "add",
    };

    const data = await fetch(session ? `/api/auth/user?session=${session.user.email}` : '', {
        method: "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ enteredInput }),
    }).then((res) => {
        if (res.ok) {

            if (setInterestToastVisible) {
                setInterestToastVisible(true)
            }

            update();
        } else {
            setInterestErrorToastVisible(true)
            Sentry.captureException('Failed to update interest');
            throw new Error("Failed to update interest");
        }
    });
}

//watchlist
export const handleAddInterests = async ({ session, selectCoin,
    update,
    mutate,
    router,
    interestMutate, dispatch, setInterestToastVisible, setInterestErrorToastVisible, fromStrategy }: any) => {
    const alarmRegister = async () => {
        await axios.post(session?.user?.email ? `/api/auth/alarm` : "", {
            email: session.user.email,
            ITEM_CD_DL: selectCoin.map((coin: any) => coin?.ticker || coin?.ITEM_CD_DL),
        });
        update();
    };

    try {
        const response = await axios.put(
            `/api/auth/user?session=${session.user.email}`,
            {
                enteredInput: {
                    action: "add",
                    interest: selectCoin.map((coin: any) => ({
                        ...coin,
                        krName: coin?.ITEM_KR_NM,
                        name: coin?.ITEM_ENG_NM,
                        ticker: coin?.ITEM_CD_DL || coin?.ticker,
                    })),
                },
            }
        );

        if (response.status === 200) {
            if (setInterestToastVisible) {
                setInterestToastVisible(true)
            }
            else {
                toast(
                    router.locale == "ko"
                        ? "관심 자산에 등록됐습니다"
                        : "Successfully added",
                    { hideProgressBar: true, autoClose: 2000, type: "success" }
                );

            }

            let isNotAgreeUser = false;
            // 알림 등록 로직
            //selectCoin 중 session?.user?.alarm에 포함되지 않은 종목만 고르기
            const addAlarmAssets = selectCoin.filter(
                (asset: any) =>
                    !session?.user?.alarm.includes(asset.ITEM_CD_DL)
            );

            if (addAlarmAssets.length > 0) {
                const response: any = await axios
                    .get(
                        session?.user?.email
                            ? `/api/auth/alarm?email=${session.user.email}`
                            : ""
                    )
                    .then((res) => res.data);
                isNotAgreeUser = response?.isNotAgreeUser === 0;
                //이미 등록해놓은 알림 종목이 1개이상 존재할 경우 -> 메서드를 등록한 유저
                if (session?.user?.alarm && session?.user?.alarm?.length > 0) {
                    if (response?.isRiskAlarmOn === 0) {
                        //유저가 알림을 OFF로 설정해놓았을 경우
                        return;
                    }
                    //자동으로 알림 등록
                    await alarmRegister();
                    return;
                }

                //메서드는 등록해 놓았지만 알림종목이 0개인 상태에서 관심등록을 시도할 경우
                if (!isNotAgreeUser) {
                    if (response?.isRiskAlarmOn === 0) {
                        //유저가 알림을 끈 상태
                        return;
                    }
                    //자동으로 알림 등록
                    await alarmRegister();
                    return;
                }


            }



            await update();
            await mutate();
            await interestMutate();
            if (fromStrategy) return;
            router.push("/?type=my");

            //메서드를 등록하지 않았을 때 알림 등록 모달 노출
            const hideModal = localStorage.getItem("hideModal");
            if (hideModal && new Date(hideModal) > new Date()) {
                return;
            }
            if (isNotAgreeUser) {
                const timer = setTimeout(() => {
                    const hideUntil = hideModal ? new Date(hideModal) : null;

                    if (!hideUntil || hideUntil < new Date()) {
                        if (dispatch) {
                            dispatch({
                                type: "SET_ALARM_MODAL_VISIBLE",
                                payload: {
                                    alarmModal: true,
                                    ITEM_CD_DL: selectCoin.map((coin: any) => coin.ITEM_CD_DL),
                                },
                            });
                        }

                    }
                }, 1000);
            }

        } else {
            if (setInterestErrorToastVisible) {
                setInterestErrorToastVisible(true);
            }
            toast(
                router.locale == "ko"
                    ? "관심 자산 등록에 실패했습니다"
                    : "Fetch Error",
                { hideProgressBar: true, autoClose: 2000, type: "error" }
            );
            throw new Error("Failed to update interest");
        }
    } catch (error) {
        Sentry.captureException(error);
        console.error("Error while adding interests:", error);
    }
};



export default useInterest;