import Image from "next/image";
import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";
import useHandleImageError from "utils/useHandleImageError";

interface props {
  src: string;
  flag?: string;
  HR_ITEM_NM: string;
  totalscore?: number;
}

const Img = ({ src, HR_ITEM_NM, flag, totalscore }: props) => {
  const handleImageError = useHandleImageError();
  const router = useRouter();
  return (
    <div
      className={`relative ${
        totalscore
          ? "w-[54px] h-[50px]"
          : "w-[40px] h-[40px] flex justify-center items-center"
      }   shrink-0`}
    >
      <Image
        unoptimized
        quality={100}
        alt="img"
        height={40}
        width={40}
        src={src}
        style={{
          borderRadius: "100%",
          objectFit: "cover",
          width: "40px",
          height: "40px",
        }}
        onError={(event) => handleImageError(event, HR_ITEM_NM)}
        key={`image-${src}`}
        priority={true}
      />
      {flag && (
        <div className="absolute bottom-0 right-0 w-[15px] h-[10px] ">
          <Image
            alt="img"
            height={100}
            width={100}
            src={flag}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            className=" bg-gray-400 rounded-sm border border-white box-content"
          />
        </div>
      )}
      {totalscore && (
        <div className="total-score absolute bottom-[2px] right-[2px]  px-1 py-[2px] rounded-[8px] bg-gray80 border-[1.5px] border-white h-[22px]  flex items-center justify-center">
          <span className={`${TYPO.TYPO9_MD} text-gray2`}>
            {totalscore}
            {router.locale === "ko" ? "점" : "p"}
          </span>
        </div>
      )}
    </div>
  );
};

export default Img;
