// states/deviceState.ts
import { useParams } from 'next/navigation';
import { atomFamily, useRecoilValue, useRecoilState } from 'recoil';


export const detailLoadingState = atomFamily({
    key: 'detailLoadingState',
    default: true, // 초기 로딩 상태
});

const useIsDetailLoading = () => {
    const { ticker } = useParams();
    const isLoading = useRecoilValue(detailLoadingState(ticker));
    return isLoading;
};

export default useIsDetailLoading;