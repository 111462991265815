import { MouseEvent, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import Head from "next/head";
import Icon, { ICONS } from "components/common/Icons";
import { useNotifications } from "contexts/NotificationContext";
import Button from "components/common/buttons/buttons";
import SearchModal from "components/templates/common/Detail/SearchModal";

const TopBar = ({ searchModal, setSearchModal }: any) => {
  // const { newNotification } = useNotifications();
  // const [searchModal, setSearchModal] = useState(false);

  const router = useRouter();

  const handleMainPage = (e: MouseEvent<HTMLAnchorElement>) => {
    if (router.pathname.startsWith("/b2b")) {
      router.push("/b2b");
      return;
    }
    if (router.pathname.startsWith("/detail")) {
      window.dataLayer.push({
        event: "ClickDetailHeaderLogo",
      });
    }
    e.preventDefault();
    sessionStorage.removeItem(`main-scrollPosition`);
    sessionStorage.removeItem(`search-scrollPosition`);
    window.location.href = `/${router.locale}`;
  };

  if (router.pathname === "/learn") {
    return <></>;
  }
  return (
    <header className="fixed top-0 w-full z-[31] bg-white h-16 border-b border-gray-100 py-[14px] px-5  max-w-full ">
      {/* <Head>
        <link rel="Icon" href="/images/favicon.png" type="image/x-icon" />
        <title>Riskweather</title>
        <meta
          property="og:image"
          content="https://riskweather.io/images/favicon.png"
        />
        <meta
          property="og:description"
          content={
            router.locale === "ko"
              ? "투자 리스크의 기준 - 리스크웨더"
              : "Invest with Confidence, We've Got the Risks Covered"
          }
        />
        <meta name="description" content={"투자 리스크의 기준 - 리스크웨더"} />
        <meta property="og:title" content="Riskweather" />
      </Head> */}

      <div className="max-w-[1000px] h-full mx-auto flex items-center justify-between w-full">
        <Link
          href={router.pathname === "/b2b" ? "/b2b" : "/"}
          onClick={handleMainPage}
        >
          <Image
            width={30}
            height={30}
            unoptimized
            src={`/images/header/headerLogo.svg`}
            alt="logo"
            className="cursor-pointer w-auto h-auto"
          />
        </Link>
        {router.pathname.startsWith("/b2b") && (
          <div>
            <Button
              type="secondary"
              size="sm"
              text={"리스크웨더로 이동하기"}
              icon={ICONS.FORWARD}
              iconHeight={12}
              iconWidth={12}
              clickHandler={() => {
                router.push("/");
              }}
            />
          </div>
        )}
        {router.pathname !== "/login" &&
          router.pathname !== "/signup" &&
          !router.pathname.startsWith("/b2b") && (
            <section className="flex justify-center items-center gap-4">
              {/* <Image
              src={"/images/header/search.svg"}
              alt=""
              className="w-auto h-auto cursor-pointer"
              width={30}
              height={30}
              onClick={() => {
                router.push("/search-list");
              }}
            /> */}
              {/* <Image
              src={"/images/header/noti.svg"}
              alt=""
              className="w-auto h-auto cursor-pointer"
              width={30}
              height={30}
              onClick={() => {
                router.push("/alarm");
              }}
            /> */}
              {/* <div
                onClick={() => {
                  router.push("/alarm");
                }}
                className="cursor-pointer"
              >
                <Icon
                  name={newNotification ? ICONS.NOTI_ON : ICONS.NOTI_OFF}
                  height={30}
                  width={30}
                  fill="gray40"
                />
              </div> */}
              <div className="flex items-center gap-[2px]">
                <div
                  onClick={() => {
                    setSearchModal(true);
                  }}
                  className="cursor-pointer p-[6px]"
                >
                  <Icon
                    name={ICONS.SEARCH}
                    height={30}
                    width={30}
                    fill="gray40"
                  />
                </div>
                <div
                  onClick={() => {
                    router.push("/service");
                    setSearchModal(false);
                  }}
                  className="cursor-pointer p-[6px]"
                >
                  <Icon
                    name={ICONS.MENU}
                    height={30}
                    width={30}
                    fill="gray40"
                  />
                </div>
              </div>
            </section>
          )}
      </div>
      {/* {searchModal && <SearchModal setSearchModal={setSearchModal} />} */}
    </header>
  );
};

export default TopBar;
