import Button from "components/common/buttons/buttons";
import Icon, { ICONS } from "components/common/Icons";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "next-i18next";
import useIsMobile from "states/deviceState";
import { TYPO } from "styles/Typo";

const ErrorIcon = () => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    // >
    //   <path
    //     d="M21.1746 19.5144C21.5449 20.1809 21.063 21 20.3005 21L3.69951 21C2.93703 21 2.45506 20.1809 2.82536 19.5144L11.1258 4.57348C11.5068 3.88767 12.4932 3.88767 12.8742 4.57348L21.1746 19.5144Z"
    //     fill="#FA4D56"
    //   />
    //   <path
    //     d="M10.6598 10.4915C10.5744 9.69479 11.1988 9 12 9C12.8012 9 13.4256 9.69479 13.3402 10.4915L12.8299 15.2543C12.7845 15.6784 12.4265 16 12 16C11.5735 16 11.2155 15.6784 11.1701 15.2543L10.6598 10.4915Z"
    //     fill="white"
    //   />
    //   <rect x="11" y="17" width="2" height="2" rx="1" fill="white" />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="10" fill="#F99F01" />
      <path
        d="M10.6598 8.49146C10.5744 7.69479 11.1988 7 12 7C12.8012 7 13.4256 7.69479 13.3402 8.49146L12.8299 13.2543C12.7845 13.6784 12.4265 14 12 14C11.5735 14 11.2155 13.6784 11.1701 13.2543L10.6598 8.49146Z"
        fill="white"
      />
      <rect x="11" y="15" width="2" height="2" rx="1" fill="white" />
    </svg>
  );
};

const CheckIcon = ({ fill }: { fill?: any }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M11.1 13.8L8.95 11.65C8.76667 11.4667 8.53333 11.375 8.25 11.375C7.96667 11.375 7.73333 11.4667 7.55 11.65C7.36667 11.8333 7.275 12.0667 7.275 12.35C7.275 12.6333 7.36667 12.8667 7.55 13.05L10.4 15.9C10.6 16.1 10.8333 16.2 11.1 16.2C11.3667 16.2 11.6 16.1 11.8 15.9L17.45 10.25C17.6333 10.0667 17.725 9.83333 17.725 9.55C17.725 9.26667 17.6333 9.03333 17.45 8.85C17.2667 8.66667 17.0333 8.575 16.75 8.575C16.4667 8.575 16.2333 8.66667 16.05 8.85L11.1 13.8ZM12.5 22C11.1167 22 9.81667 21.7375 8.6 21.2125C7.38333 20.6875 6.325 19.975 5.425 19.075C4.525 18.175 3.8125 17.1167 3.2875 15.9C2.7625 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.7625 9.31667 3.2875 8.1C3.8125 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.3125 8.6 2.7875C9.81667 2.2625 11.1167 2 12.5 2C13.8833 2 15.1833 2.2625 16.4 2.7875C17.6167 3.3125 18.675 4.025 19.575 4.925C20.475 5.825 21.1875 6.88333 21.7125 8.1C22.2375 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2375 14.6833 21.7125 15.9C21.1875 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6875 16.4 21.2125C15.1833 21.7375 13.8833 22 12.5 22Z"
        fill={fill ? fill : "#4589FF"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.95039 11.6492L11.1004 13.7992L16.0504 8.84922C16.2337 8.66589 16.4671 8.57422 16.7504 8.57422C17.0337 8.57422 17.2671 8.66589 17.4504 8.84922C17.6337 9.03255 17.7254 9.26589 17.7254 9.54922C17.7254 9.83255 17.6337 10.0659 17.4504 10.2492L11.8004 15.8992C11.6004 16.0992 11.3671 16.1992 11.1004 16.1992C10.8337 16.1992 10.6004 16.0992 10.4004 15.8992L7.55039 13.0492C7.36706 12.8659 7.27539 12.6326 7.27539 12.3492C7.27539 12.0659 7.36706 11.8326 7.55039 11.6492C7.73372 11.4659 7.96706 11.3742 8.25039 11.3742C8.53372 11.3742 8.76706 11.4659 8.95039 11.6492Z"
        fill="white"
      />
    </svg>
  );
};

export function MembershipRecommandToast({
  message,
  isVisible,
  onClose,
  isSuccess = true,
  width,
  fromTop = false,
  buttonMessage,
  buttonHanlder,
}: any) {
  const [isClient, setIsClient] = useState(false);
  const { t } = useTranslation("detail");
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000); //
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  const isMobile = useIsMobile();
  const portalTarget = isClient ? document.getElementById("portal") : null;

  if (!portalTarget) return <></>;

  return createPortal(
    <div
      style={{
        position: "fixed",
        // bottom: "40px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",
        color: "white",
        padding: "10px",
        borderRadius: "16px",
        zIndex: 1000,
        minWidth: width ? `${width}px` : "327px",
      }}
      className={`${
        isVisible
          ? fromTop
            ? isMobile
              ? "top-toast "
              : "top-desktop-toast"
            : "bottom-toast"
          : "hidden"
      } px-4 py-[10px] flex gap-3 items-center`}
    >
      <div className="flex gap-2 items-center grow">
        <div className="shrink-0">
          {" "}
          {/* <Icon name={ICONS.CHECK_FILL} height={24} width={24} fill="#4589FF" /> */}
          {isSuccess ? <CheckIcon /> : <ErrorIcon />}
        </div>

        <p className={`${TYPO.TYPO7_MD} text-gray10 whitespace-pre-line`}>
          {message}
        </p>
      </div>

      <Button
        type="primary"
        text={t("freeSubscribe")}
        size="sm"
        clickHandler={() => {
          if (buttonHanlder) {
            window.dataLayer.push({
              event: "cDetailToastMembership",
            });
            buttonHanlder();
          }
        }}
      />
      {/* <div
        className={`cursor-pointer h-[32px] px-2 py-[6px] flex items-center justify-center bg-gray5 rounded-[8px] ${TYPO.TYPO8_MD} text-gray60 `}
        onClick={() => {
          if (buttonHanlder) {
            buttonHanlder();
          }
        }}
      >
        {buttonMessage}
      </div> */}
    </div>,
    portalTarget // portal 대상
  );
}
export function RebalanceSaveToast({
  message,
  isVisible,
  onClose,
  isSuccess = true,
  width,
  fromTop = false,
  buttonMessage,
  buttonHanlder,
}: any) {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000); //
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  const isMobile = useIsMobile();
  const portalTarget = isClient ? document.getElementById("portal") : null;

  if (!portalTarget) return <></>;

  return createPortal(
    <div
      style={{
        position: "fixed",
        // bottom: "40px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",
        color: "white",
        padding: "10px",
        borderRadius: "16px",
        zIndex: 1000,
        minWidth: width ? `${width}px` : "327px",
      }}
      className={`${
        isVisible
          ? fromTop
            ? isMobile
              ? "top-toast "
              : "top-desktop-toast"
            : "bottom-toast"
          : "hidden"
      } px-4 py-[10px] flex gap-3 items-center`}
    >
      <div className="flex gap-2 items-center grow">
        <div className="shrink-0">
          {" "}
          {/* <Icon name={ICONS.CHECK_FILL} height={24} width={24} fill="#4589FF" /> */}
          {isSuccess ? <CheckIcon /> : <ErrorIcon />}
        </div>

        <p className={`${TYPO.TYPO6_MD} text-gray10 whitespace-pre-line`}>
          {message}
        </p>
      </div>
      <div
        className={`cursor-pointer h-[32px] px-2 py-[6px] flex items-center justify-center bg-gray5 rounded-[8px] ${TYPO.TYPO8_MD} text-gray60 `}
        onClick={() => {
          if (buttonHanlder) {
            buttonHanlder();
          }
        }}
      >
        {buttonMessage}
      </div>
    </div>,
    portalTarget // portal 대상
  );
}

function CustomToastComponent({
  message,
  isVisible,
  onClose,
  isSuccess = true,
  width,
  fromTop = false,
  duration = 1000,
  successIconFill,
}: any) {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, duration); // 1초 후 토스트 숨김
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  const isMobile = useIsMobile();
  const portalTarget = isClient ? document.getElementById("portal") : null;

  if (!portalTarget) return <></>;

  return createPortal(
    <div
      style={{
        position: "fixed",
        // bottom: "40px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",
        color: "white",
        padding: "10px",
        borderRadius: "16px",
        zIndex: 1000,
        width: width ? `${width}px` : "220px",
      }}
      className={`${
        isVisible
          ? fromTop
            ? isMobile
              ? "top-toast "
              : "top-desktop-toast"
            : "bottom-toast"
          : "hidden"
      } px-4 py-[10px] flex gap-2 items-center`}
    >
      <div className="shrink-0">
        {" "}
        {/* <Icon name={ICONS.CHECK_FILL} height={24} width={24} fill="#4589FF" /> */}
        {isSuccess ? (
          <CheckIcon fill={successIconFill ? successIconFill : undefined} />
        ) : (
          <ErrorIcon />
        )}
      </div>

      <p className={`${TYPO.TYPO6_MD} text-gray10 whitespace-pre-line`}>
        {message}
      </p>
    </div>,
    portalTarget // portal 대상
  );
}

const CustomToast = React.memo(CustomToastComponent);

export default CustomToast;
