import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { NowTrendingData } from "interfaces/main";
import Button from "components/common/buttons/buttons";
import { useTranslation } from "next-i18next";
import Title from "components/common/title";
import MonetaryBtn from "components/common/monetaryBtn";
import InterestAssetList from "components/templates/common/Interest/AssetList";
import { ICONS } from "components/common/Icons";
import ToggleButton from "components/common/buttons/toggle";
import { TYPO } from "styles/Typo";
import SelectModal from "components/modals/SelectOptionModal";
import { useSortDispatch, useSortState } from "contexts/SortContext";
import { INTEREST_SORT } from "utils/constant/STATUS";

interface Props {
  data: any[];
  isValid: boolean;
  session: any;
}

function InterestTable({ session, data, isValid }: Props) {
  const router = useRouter();
  const { t } = useTranslation("watchlist");

  const [sortedData, setSortedData] = useState(data);

  const [toggleActive, setToggleActive] = useState(false);

  const [sortSelectModal, setSortSelectModal] = useState(false);

  const { WATCHLIST_SORT: sortSelected } = useSortState();
  const sortDispatch = useSortDispatch();
  const setSortSelected = (sortIndex: 0 | 1 | 2 | 3) => {
    sortDispatch({ type: "SET_WATCHLIST_SORT", payload: { sort: sortIndex } });
  };

  useEffect(() => {
    let sorted = [...(data || [])];

    switch (sortSelected) {
      case 0:
        break;
      case 1:
        sorted.sort((a, b) => a.ITEM_KR_NM.localeCompare(b.ITEM_KR_NM));
        break;
      default:
        break;
    }

    setSortedData(sorted);
  }, [sortSelected, data]);

  return (
    <div
      className={`flex flex-col  ${
        data?.length > 0 ? "overflow-y-auto " : ""
      } `}
      style={{ minHeight: "calc(100vh - (5rem + 50px))" }}
    >
      <div
        className={`flex-grow  ${
          data?.length > 0 ? "overflow-y-auto " : ""
        } pb-4 `}
      >
        <div className="flex justify-between items-center my-4">
          <Title
            title={t("watchlist")}
            size="lg"
            buttonChildren={
              data && data.length > 0 ? (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    router.push("/watchlist/edit");
                  }}
                >
                  {router.locale === "ko" ? "편집" : "Edit"}
                </div>
              ) : null
            }
          />
        </div>
        {data && data.length > 0 && (
          <>
            <div className="px-6 flex justify-between items-center my-4">
              <Button
                icon={ICONS.EXPAND}
                iconHeight={16}
                iconWidth={16}
                text={
                  INTEREST_SORT[sortSelected][router.locale === "ko" ? 0 : 1]
                }
                clickHandler={() => setSortSelectModal(true)}
                type="link_gray"
                size="md"
              />
              <div className="flex items-center gap-1">
                <MonetaryBtn
                  hidden={!data || data?.length === 0}
                  isActive={toggleActive}
                  setIsActive={setToggleActive}
                  smallVer={true}
                />
              </div>
            </div>
          </>
        )}

        <InterestAssetList
          isValid={isValid}
          toggleActive={toggleActive}
          t={t}
          sortedData={sortedData}
        />

        <div className=" flex justify-center py-3 px-[24px]">
          <Button
            text={t("addAssetsButton")}
            size="lg"
            width="w-full "
            type="tertiary"
            clickHandler={() => {
              if (!session) router.push("/login");
              else router.push("/watchlist/search");
            }}
            icon={ICONS.PLUS}
            iconHeight={16}
            iconWidth={16}
          />
        </div>
      </div>
      {sortSelectModal && (
        <SelectModal
          data={[
            ...INTEREST_SORT.map(
              (sort) => sort[router.locale === "ko" ? 0 : 1]
            ),
          ]}
          index={sortSelected}
          selectIndex={setSortSelected}
          setModal={setSortSelectModal}
          isModalOpen={sortSelectModal}
        />
      )}
    </div>
  );
}

export default InterestTable;
