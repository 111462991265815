import BottomModal from "components/common/Modal/BottomModal";
import Button from "components/common/buttons/buttons";
import { useTranslation } from "next-i18next";
import { TYPO } from "styles/Typo";
import useModalClickOutside from "utils/useModalClickOutside";

const DetailChartSVSModal = ({ setModal, isModalOpen }: any) => {
  const modalRef = useModalClickOutside(setModal); // 모달 참조를 위한 ref 추가
  const { t } = useTranslation("detail");

  return (
    <BottomModal
      isOpen={isModalOpen}
      modalRef={modalRef}
      containerClass={`gap-4 !pb-9`}
    >
      <div className="flex flex-col gap-4">
        <h3 className={`${TYPO.TYPO4_B} text-gray80`}>
          {t("svsExplainTitle")}
        </h3>
        <div className={` text-gray60 break-keep whitespace-pre-line`}>
          <span className={`${TYPO.TYPO5_1_RG}`}>{t("svgDesModal")}</span>
        </div>
      </div>

      <Button
        type={"primary"}
        size={"lg"}
        text={t("confirm")}
        clickHandler={() => setModal(false)}
        width="w-full"
      />
    </BottomModal>
  );
};

export default DetailChartSVSModal;
