import List from "components/common/List/list";
import Loading from "components/organisms/m/Loading";
import { NowTrendingData } from "interfaces/main";
import Image from "next/image";
import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";
import { toDetailPage } from "utils/navigateToDetailPage";

function InterestAssetList({
  sortedData,
  isValid,
  toggleActive,
  t,
}: {
  sortedData: any;
  isValid: boolean;
  toggleActive: boolean;

  t: any;
}) {
  const router = useRouter();
  return (
    <div className=" flex flex-col  border-b-gray-100">
      {sortedData && sortedData.length > 0 ? (
        <div>
          <div className="pt-4 flex flex-col gap-2">
            {sortedData.map((asset: any, i: number) => (
              <ul
                key={i}
                className="cursor-pointer flex justify-between  gap-3"
                onClick={() => toDetailPage(router, asset.ITEM_CD_DL)}
              >
                <List
                  src={
                    `https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/${asset.ITEM_CD_DL}.png` ||
                    "https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/errorLogo.png"
                    // `/images/logos/${asset.ITEM_CD_DL}.png` ||
                    // "/images/logos/errorLogo.png"
                  }
                  item={
                    router.locale === "ko"
                      ? asset.ITEM_KR_NM && asset.ITEM_KR_NM
                      : asset.ITEM_ENG_NM && asset.ITEM_ENG_NM
                  }
                  price={
                    asset.CAT === "Index"
                      ? asset.ADJ_CLOSE &&
                        asset.ADJ_CLOSE.toLocaleString("en-us", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      : !toggleActive
                      ? asset.ADJ_CLOSE &&
                        asset.ADJ_CLOSE.toLocaleString("en-us", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        }) +
                          (asset.CAT !== "Index"
                            ? ` ${asset?.CURR ? asset?.CURR : ""}`
                            : "")
                      : router.locale === "ko"
                      ? asset.ADJ_CLOSE_KRW &&
                        asset.ADJ_CLOSE_KRW.toLocaleString("en-us", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }) + (asset.CAT !== "Index" ? "원" : "")
                      : asset.ADJ_CLOSE_USD &&
                        asset.ADJ_CLOSE_USD.toLocaleString("en-us", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }) + (asset.CAT !== "Index" ? "$" : "")
                  }
                  HR_ITEM_NM={asset.HR_ITEM_NM}
                  percent={
                    asset?.ADJ_CLOSE_CHG
                      ? asset.ADJ_CLOSE_CHG.toLocaleString("en-us", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  rightIcon={() => {
                    return (
                      <div
                        className={`${
                          asset?.TOTAL_PER ? "" : "hidden"
                        } truncate flex grow shrink-0 justify-end items-center gap-4`}
                      >
                        <span className={`${TYPO.TYPO6_RG} text-gray70`}>
                          {/* {router.locale === "ko"
                            ? asset?.CVaR_LV_KR
                            : asset?.CVaR_LV} */}
                          {router.locale === "ko"
                            ? `${asset?.TOTAL_PER}점`
                            : `${asset?.TOTAL_PER}p`}
                        </span>
                      </div>
                    );
                  }}
                />
              </ul>
            ))}
          </div>
        </div>
      ) : isValid ? (
        <Loading />
      ) : (
        <div className=" p-5 flex-col justify-center items-center gap-6 inline-flex">
          <div className=" h-[110px] flex-col justify-center items-center gap-1 inline-flex">
            <Image
              src={"/images/icons/interestStar.svg"}
              width={120}
              height={80}
              alt="interestLogo"
            />
            <span className={`${TYPO.TYPO6_MD} text-gray40`}>
              {t("noAssetsMessage")}
            </span>
          </div>
          <div className="text-center">
            <span
              className={`${TYPO.TYPO6_MD} text-gray70 whitespace-pre-line`}
            >
              {t("addAssetsDescription")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default InterestAssetList;
