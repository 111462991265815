import * as Sentry from "@sentry/react";
import axios from "axios";

//----------최종 알림 받기 신청 로직-------
export const stockInitialAlarmRegister = async ({ update, session, asset }: { update?: any, session: any, asset: any }) => {
    try {
        //asset.ITEM_CD_DL 가 여러개일 경우
        //asset.ITEM_CD_DL값이  ","이 포함되어있을 경우
        let ITEM_CD_DL = asset.ITEM_CD_DL;
        if (asset.ITEM_CD_DL?.includes(",")) {
            ITEM_CD_DL = asset.ITEM_CD_DL?.split(",");
        }


        const response = await axios.post(`/api/auth/alarm`, {
            email: session?.user?.email || undefined,
            ITEM_CD_DL: ITEM_CD_DL, //문자 or 문자배열
            method: 1,
        });
        if (response.status === 200) {
            update();

        }
    } catch (e: any) {
        console.log(e);
        Sentry.captureException("Error:" + e);
        throw new Error("Failed to register alarm");

    }
};