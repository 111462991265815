import { useState, useEffect, useCallback } from "react";
import useIsMobile from "states/deviceState";
import SearchHeaderInput from "../SearchList/HeaderInput";
import useDebounce from "utils/useDebounce";
import useSearchList, {
  useSearchListVerLight,
} from "utils/clientAPIs/searchList";
import { useTranslation } from "react-i18next";
import SearchTable from "../Search/SearchTable";
import SearchList from "components/pages/w/SearchList";
import { useRouter } from "next/router";
import ListWithInterest from "../components/ListWithInterest";
import EmptySearchItem from "../components/EmptySearchItem";
import Loading from "components/organisms/m/Loading";
import { TYPO } from "styles/Typo";
import Tabs from "components/common/Tab/tabs";
import Tab from "components/common/Tab/tab";

function SearchModal({ setSearchModal }: { setSearchModal: any }) {
  const isMobile = useIsMobile();
  const router = useRouter();
  const [search, setSearch] = useState("");
  const { t } = useTranslation("search");

  const [debouncedValue, searchTranslated] = useDebounce(search, 100);

  const [data, isValid] = useSearchListVerLight({
    search: searchTranslated[0],
    search2: searchTranslated[1],
    searchOrigin: debouncedValue,
  });

  const onSearch = useCallback((query: string) => {
    setSearch(query);
  }, []);

  const [type, setType] = useState(
    router.locale === "en" ? "United States" : "Korea (South)"
  );
  const [typeData, setTypeData] = useState([...(data || [])]);
  useEffect(() => {
    if (!debouncedValue) {
      let sorted = [...(data || [])];
      if (type === "Korea (South)") {
        sorted = sorted.filter((item) => item.ITEM_CD.toString()[0] == 5);
      } else if (type === "United States") {
        sorted = sorted.filter((item) => item.ITEM_CD.toString()[0] == 2);
      } else if (type === "Crypto") {
        sorted = sorted.filter((item) => item.ITEM_CD.toString()[0] == 3);
      }
      sorted.sort((a, b) => a.RANK - b.RANK);
      setTypeData(sorted);
    } else {
      setTypeData(data);
    }
  }, [type, debouncedValue, isValid]);
  const currentDate = new Date();
  const year = currentDate.getFullYear() % 100; // 2024년의 경우 24로 변환
  const month = currentDate.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줌
  const day = currentDate.getDate();

  return (
    <div
      className={`bg-white absolute top-0  pb-[50px] z-[30] flex flex-col  items-center ${
        isMobile
          ? "top-0 left-0 w-screen "
          : "top-[60px] left-[50%] transform -translate-x-1/2 w-[800px]"
      }
      min-h-screen
      `}
    >
      {isMobile && (
        <div className="w-full">
          <SearchHeaderInput
            search={search}
            onSearch={onSearch}
            t={t}
            backHandler={() => {
              setSearchModal(false);
            }}
          />
          <SearchTable
            search={debouncedValue}
            t={t}
            data={data}
            isValid={isValid}
            listClickHandler={() => {
              setTimeout(() => {
                setSearchModal(false);
              }, 300);
            }}
          />
        </div>
      )}
      {!isMobile && (
        <main className=" slim-scroll  bg-white w-screen  max-w-[800px] min-h-screen pt-3">
          <div className="flex flex-col  gap-1">
            <SearchHeaderInput
              search={search}
              onSearch={onSearch}
              t={t}
              backHandler={() => {
                setSearchModal(false);
              }}
            />

            {debouncedValue.length === 0 && (
              <>
                <Tabs type="line" isFull={true}>
                  <Tab
                    title={router.locale === "ko" ? "국내주식" : "Korean"}
                    onClick={() => {
                      setType("Korea (South)");
                    }}
                    isActive={type === "Korea (South)"}
                    type={"line"}
                  />
                  <Tab
                    title={router.locale === "ko" ? "해외주식" : "Global"}
                    onClick={() => {
                      setType("United States");
                    }}
                    isActive={type === "United States"}
                    type={"line"}
                  />
                  <Tab
                    title={router.locale === "ko" ? "가상자산" : "Crypto"}
                    onClick={() => {
                      setType("Crypto");
                    }}
                    isActive={type === "Crypto"}
                    type={"line"}
                  />
                </Tabs>
                <div className="px-6 py-4 w-full flex-col flex">
                  <span className={`${TYPO.TYPO7_MD} !text-[14px] text-gray50`}>
                    {router.locale === "ko"
                      ? `${year}년 ${month}월 ${day}일`
                      : `${year}.${month}.${day}`}
                  </span>
                  <span className={`${TYPO.TYPO6_SB} text-gray80`}>
                    {router.locale === "ko"
                      ? "사람들이 많이 보고 있어요"
                      : "Trending"}
                  </span>
                </div>
              </>
            )}

            {/**검색 리스트 - default 사람들이 가장 많이 보는 자산 */}
            <div>
              {typeData.length > 0 && !isValid && (
                <ListWithInterest
                  data={typeData}
                  count={
                    debouncedValue && debouncedValue?.length > 0 ? 100 : 10
                  }
                  type={type}
                  isSearching={
                    (debouncedValue && debouncedValue?.length > 0) || false
                  }
                  listClickHandler={() => {
                    setTimeout(() => {
                      setSearchModal(false);
                    }, 300);
                  }}
                />
              )}
              {typeData.length === 0 && !isValid && <EmptySearchItem />}
              {isValid && <Loading />}
            </div>
          </div>
        </main>
      )}
    </div>
  );
}

export default SearchModal;
