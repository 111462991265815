const SkeletonBlock = ({
  radius = 12,
  width,
  height,
}: {
  radius?: number | string;
  width: number | string;
  height: number | string;
}) => {
  return (
    <div
      className={"bg-gray5 "}
      style={{
        borderRadius: radius,
        width: width,
        height: height,
      }}
    ></div>
  );
};

export const SkeletonCircle = ({}: {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      className="text-gray5"
    >
      <g id="Risk_Guage_Chart">
        <path
          id="Ellipse 408"
          d="M22.0802 88.4283C21.2686 89.5453 19.7007 89.7977 18.6256 88.9313C11.0821 82.8523 5.45204 74.6989 2.44717 65.4508C-0.81573 55.4087 -0.815729 44.5913 2.44717 34.5491C5.71007 24.507 12.0684 15.7555 20.6107 9.54914C29.1531 3.34274 39.441 -4.82894e-06 50 -2.18557e-06C60.559 4.578e-07 70.8469 3.34275 79.3893 9.54916C87.9317 15.7556 94.2899 24.507 97.5528 34.5492C100.816 44.5913 100.816 55.4087 97.5528 65.4509C94.5479 74.6989 88.9179 82.8523 81.3744 88.9313C80.2993 89.7977 78.7313 89.5453 77.9198 88.4283C77.1082 87.3113 77.3609 85.7532 78.4311 84.8808C85.1227 79.4267 90.119 72.1495 92.7975 63.9058C95.7341 54.8678 95.7342 45.1322 92.7975 36.0942C89.8609 27.0563 84.1385 19.18 76.4503 13.5942C68.7622 8.00848 59.5031 5 50 5C40.4969 5 31.2378 8.00847 23.5497 13.5942C15.8615 19.18 10.1391 27.0563 7.20246 36.0942C4.26584 45.1322 4.26584 54.8678 7.20245 63.9058C9.88099 72.1495 14.8772 79.4267 21.5689 84.8808C22.6391 85.7531 22.8918 87.3113 22.0802 88.4283Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const SkeletonBlock2 = ({
  radius = 12,
  width,
  height,
}: {
  radius?: number | string;
  width: number | string;
  height: number | string;
}) => {
  return (
    <div
      className={"bg-gray10 "}
      style={{
        borderRadius: radius,
        width: width,
        height: height,
      }}
    ></div>
  );
};

export default SkeletonBlock;
