import Image from "next/image";
import { TYPO } from "styles/Typo";

type TabProps = {
  title: string;
  onClick: () => void;
  isActive: boolean;
  type: "line" | "pill";
  isIcon?: boolean;
  isFull?: boolean;
  enable?: boolean;
  index?: number;
  tabClassNameAdd?: string;
};
const Tab: React.FC<TabProps> = ({
  title,
  onClick,
  isActive,
  type,
  isIcon,
  enable = true,
  isFull,
  index,
  tabClassNameAdd,
}: TabProps) => {
  if (type === "pill") {
    const tabClassName = `${
      isActive
        ? `${TYPO.TYPO6_B} text-white bg-black `
        : `${TYPO.TYPO6_SB} ${enable ? "text-gray50" : "text-gray40"} `
    } px-3 py-1.5 rounded-[50px] shrink-0 flex justify-center items-center text-center cursor-pointer gap-[2px] whitespace-nowrap`;
    return (
      <div
        className={` ${tabClassName} `}
        onClick={onClick}
        key={index ? index : 0}
      >
        <span>{title}</span>
        {isIcon ? (
          enable && isActive ? (
            <Image
              src="/assets/icons/shiningGray.svg"
              alt="shining"
              width={24}
              height={24}
            />
          ) : (
            <Image
              src="/assets/icons/shiningWhite.svg"
              alt="shining"
              width={24}
              height={24}
            />
          )
        ) : (
          ""
        )}
      </div>
    );
  } else {
    const tabClassName = `${
      isActive
        ? "text-black  border-b-2 border-black"
        : `${enable ? "text-gray40" : "text-gray40"} `
    } px-2 py-3 ${tabClassNameAdd ? tabClassNameAdd : ""} ${
      isFull && "grow"
    } text-center cursor-pointer flex gap-[2px] shrink-0 justify-center items-center`;
    return (
      <div
        className={`${TYPO.TYPO6_SB} ${tabClassName} `}
        onClick={onClick}
        key={index ? index : 0}
      >
        <span>{title}</span>
        {isIcon ? (
          enable && isActive ? (
            <Image
              src="/images/icons/shiningBlack.svg"
              alt="shining"
              width={24}
              height={24}
            />
          ) : (
            <Image
              src="/images/icons/shiningGray.svg"
              alt="shining"
              width={24}
              height={24}
            />
          )
        ) : (
          ""
        )}
      </div>
    );
  }
};

export default Tab;
