import Input from "components/common/inputs";
import Image from "next/image";
import { useRouter } from "next/router";

function SearchHeaderInput({
  search,
  onSearch,
  t,
  backHandler,
}: {
  search: any;
  onSearch: any;
  t: any;
  backHandler?: any;
}) {
  const router = useRouter();
  return (
    <div className="px-6 py-4 flex gap-3 items-center">
      <Image
        className="cursor-pointer h-6 w-6"
        src={"/images/icons/arrowLeft24.svg"}
        alt="arrow"
        width={24}
        height={24}
        onClick={() => {
          if (backHandler) {
            backHandler();
            return;
          }
          router.back();
        }}
      />
      <Input
        placeholder={
          router.locale === "ko"
            ? "종목명이나 종목 코드를 검색해보세요"
            : "Type an investment name or symbol"
        }
        value={search}
        setValue={(query: string) => onSearch(query)}
        type="box"
        size="md"
        width="w-full"
        isForcusd={true}
        // onKeyUp={handleKeyUp}
      />
    </div>
  );
}
export default SearchHeaderInput;
