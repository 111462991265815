// states/deviceState.ts
import { atom, useRecoilValue } from 'recoil';
import { v1 } from "uuid";
export const isMobileState = atom({
    key: `isMobileState/${v1()}`, // 고유한 키
    default: false, // 기본값
});

const useIsMobile = () => {
    const isMobile = useRecoilValue(isMobileState);
    return isMobile;
}

export default useIsMobile;