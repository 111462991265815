import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import useMainStockList from "utils/clientAPIs/MainStockList";
import Strategy from "./Strategy";
import { StockListSkeleton } from "components/skeleton/main";
import StockList from "./StockList";
import { useTranslation } from "next-i18next";

function StockLIstIndex({ isMobile }: { isMobile: boolean }) {
  const router = useRouter();
  const { t } = useTranslation("main");
  const [koreanData, koreanIndex, isKoreanLoading] = useMainStockList({
    loc: "korea",
  });
  const [usData, usIndex, isUsLoading] = useMainStockList({ loc: "us" });
  const [cryptoData, _, isCryptoLoading] = useMainStockList({ loc: undefined });

  const loading = isKoreanLoading || isUsLoading || isCryptoLoading;
  return (
    <div>
      <Strategy isMobile={isMobile} />
      {loading && (
        <div>
          <StockListSkeleton />
          <StockListSkeleton />
        </div>
      )}
      {!loading && (
        <StockList
          cat={"korean"}
          name={t("korean")}
          stocklist={[...koreanData]}
          indexList={[...koreanIndex]}
        />
      )}
      {!loading && (
        <StockList
          cat={"us"}
          name={t("us")}
          stocklist={[...usData]}
          indexList={[...usIndex]}
        />
      )}
      {!loading && (
        <StockList
          cat={"crypto"}
          name={t("crypto")}
          stocklist={[...cryptoData]}
        />
      )}
    </div>
  );
}

export default StockLIstIndex;
