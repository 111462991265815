import useSWR from "swr";
import { fetcher } from "./fetcher";

const useCandleChart = (ticker: string | undefined): [any, boolean] => {
    const { data: monthDt, isValidating } = useSWR(
        ticker ? `/api/candleChart?ticker=${ticker}` : null,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const data = monthDt ? [].concat(...monthDt) : [];
    const isValid = !monthDt && isValidating;

    return [data, isValid];
}
export default useCandleChart;