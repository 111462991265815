import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { GetServerSidePropsContext } from "next";
import { NextPage } from "next";
import MainPage from "components/pages/Main";
import { NowTrendingData } from "interfaces/main";
import useRestoreScroll from "utils/useRestoreScroll";
import { useRouter } from "next/router";
import { useEffect } from "react";

interface Props {
  isMobile: boolean;
  trendingData: NowTrendingData[];
  countryRiskData: NowTrendingData[];
  isHoliday?: boolean;
  themeListData?: any;
}

const Main: NextPage<Props> = ({
  isMobile,
  countryRiskData,
  trendingData,
  isHoliday,
  themeListData,
}) => {
  useRestoreScroll("main");
  // const router = useRouter();
  // useEffect(() => {
  //   if (router.locale === "en") {
  //     router.push("/?type=us", undefined, { shallow: true });
  //   }
  // }, []);

  return (
    <MainPage
      // trendingData={trendingData}
      // countryRiskData={countryRiskData}
      // isHoliday={isHoliday}
      // themeListData={themeListData}
      isMobile={isMobile}
    />
  );
};
export default Main;

export async function getServerSideProps({
  locale,
}: GetServerSidePropsContext) {
  // const start = performance.now();
  // const res = await fetch(
  //   `${process.env.NEXTAUTH_URL}/api/realTimeRisk?main=true`
  // );
  // const trendingData = await res.json();

  // const res2 = await fetch(`${process.env.NEXTAUTH_URL}/api/countryRisk`);
  // const countryRiskData = await res2.json();

  // let isHoliday = false;
  // const data = await fetch(
  //   `${process.env.NEXTAUTH_URL}/api/detail/holiday?ko=true`
  // );
  // const { todayIsHoliday } = await data.json();

  // isHoliday = todayIsHoliday;

  // const industryRes = await fetch(
  //   `${process.env.NEXTAUTH_URL}/api/industry/themeList`
  // );
  // const themeListData = await industryRes.json();

  return {
    props: {
      hasMetaData: false, // 기본 메타데이터를 렌더링하도록 설정
      // trendingData,
      // countryRiskData,
      // isHoliday,
      // themeListData,
      ...(await serverSideTranslations(locale as string, [
        "main",
        "watchlist",
      ])),
    },
  };
}
