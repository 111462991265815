import BottomModal from "components/common/Modal/BottomModal";
import Button from "components/common/buttons/buttons";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { TYPO } from "styles/Typo";
import useModalClickOutside from "utils/useModalClickOutside";
import Lottie from "lottie-react";
import alarmAnimation from "../../../public/lottie/alarm.json";
import {
  useInterestToastDispatch,
  useInterestToastState,
} from "contexts/InterestContext";
import { useRouter } from "next/router";
import BottomFloatingModal from "components/common/Modal/BottomFloatingModal";
import { stockInitialAlarmRegister } from "utils/alarm/stock";
import { useSession } from "next-auth/react";
import CustomToast from "./CustomToast";

function GlobalRiskAlarmNotiModal({}: any) {
  const dispatch = useInterestToastDispatch();
  const state = useInterestToastState();

  const modalClose = () => {
    dispatch({
      type: "SET_ALARM_MODAL_VISIBLE",
      payload: {
        alarmModal: false,
        ITEM_CD_DL: undefined,
      },
    });
  };

  const modalRef = useModalClickOutside(modalClose);
  //   const { t } = useTranslation("detail");

  const router = useRouter();

  const itemCD_DLs = state.ITEM_CD_DL;
  // console.log("itemCD_DLs", itemCD_DLs);
  let query = state.ITEM_CD_DL;
  if (Array.isArray(itemCD_DLs)) {
    query = itemCD_DLs.map((item: any) => item).join(",");
  }
  const { data: session, update }: any = useSession();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);

  return (
    <div className={state.alarmModal ? "" : "hidden"}>
      <BottomFloatingModal
        isOpen={state.alarmModal}
        modalRef={modalRef}
        containerClass="gap-[24px] !z-[900]"
      >
        <div className="flex flex-col gap-2 px-6 pt-6">
          <h1 className={`${TYPO.TYPO4_B} text-gray80 whitespace-pre-line`}>
            {router.locale === "ko"
              ? `관심있는 종목의 새로운 소식을 \n알려드릴까요?`
              : "Would you like to be notified about updates on watchlist?"}
          </h1>
          <span className={`${TYPO.TYPO7_MD} text-gray50 break-keep`}>
            {router.locale === "ko"
              ? "관심 종목의 투자 매력도나 주가 리스크가 달라지면 알려드릴게요"
              : "We'll let you know when the attractiveness score or stock risk changes"}
          </span>
        </div>
        <div className="w-full items-center justify-center flex mb-[6px]">
          <Lottie
            animationData={alarmAnimation}
            renderer="svg"
            autoplay
            loop
            style={{ width: 120, height: 120 }}
          />
        </div>
        <div className="w-full flex gap-2 z-[100] px-6 pb-6">
          <Button
            type={"text_gray"}
            size={"xl"}
            text={router.locale === "ko" ? "다음에" : "Later"}
            width="grow-[0.5]"
            clickHandler={() => {
              window.dataLayer.push({
                event: "cDetailToastAlarmNo",
              });
              const tomorrow = new Date();
              tomorrow.setDate(tomorrow.getDate() + 1);
              localStorage.setItem("hideModal", tomorrow.toISOString());
              // setModalOpen(false);
              modalClose();
            }}
          />
          <Button
            type={"primary"}
            size={"xl"}
            text={router.locale === "ko" ? "알림 받기" : "Get Noti"}
            width="grow-[0.5]"
            clickHandler={async () => {
              window.dataLayer.push({
                event: "cDetailToastAlarmYes",
              });

              modalClose();
              try {
                const asset = { ITEM_CD_DL: query };
                await stockInitialAlarmRegister({
                  update,
                  session,
                  asset,
                });
                setIsSuccess(true);
              } catch (e) {
                console.error(e);
                setIsFail(true);
              }
              // router.push(`/alarm-method?item=${query}`);
            }}
          />
        </div>
      </BottomFloatingModal>
      <CustomToast
        message={
          router.locale === "ko"
            ? "이제부터 문자로 알림을 받아요"
            : "You will now receive notifications via text message."
        }
        isVisible={isSuccess}
        onClose={() => setIsSuccess(false)}
        isSuccess={true}
        width={router.locale === "ko" ? 250 : 340}
        duration={1500}
        successIconFill={"#34BB7A"}
      />
      <CustomToast
        message={"Server ERROR!"}
        isVisible={isFail}
        onClose={() => setIsFail(false)}
        isSuccess={false}
        width={300}
        duration={1500}
      />
    </div>
  );
}
export default GlobalRiskAlarmNotiModal;
