import { Forward } from "components/common/Icons";
import SkeletonBlock, { SkeletonBlock2 } from ".";
import DividerLine from "components/common/divider/line";

export const TargetDateSkeleton = () => {
  return <SkeletonBlock width={75} height={20} radius={10} />;
};

export const RankingSkeleton = () => {
  return <SkeletonBlock width={45} height={24} radius={8} />;
};

export const GaugeSkeleton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="171"
      height="87"
      viewBox="0 0 171 87"
      fill="none"
      className="text-gray5"
    >
      <path
        d="M3.4 86C1.52223 86 -0.00709516 84.4766 0.067952 82.6004C0.83837 63.339 8.13632 44.9065 20.76 30.3382C21.9897 28.9191 24.1473 28.8555 25.5162 30.1409C26.885 31.4264 26.9463 33.5736 25.7215 34.9969C14.276 48.2987 7.63611 65.0692 6.87385 82.6007C6.79228 84.4767 5.27776 86 3.4 86Z"
        fill="currentColor"
      />
      <path
        d="M28.3001 27.3001C26.9723 25.9723 26.9681 23.8137 28.3479 22.5401C42.5125 9.46501 60.7066 1.59169 79.9343 0.216646C81.8073 0.0827006 83.3779 1.56342 83.4369 3.44026C83.4959 5.3171 82.0209 6.87872 80.1484 7.01917C62.6495 8.33174 46.0958 15.4952 33.1601 27.3528C31.7759 28.6217 29.6279 28.6279 28.3001 27.3001Z"
        fill="currentColor"
      />
      <path
        d="M144.678 30.3489C146.052 29.0683 148.209 29.1394 149.434 30.5628C162.007 45.1751 169.24 63.6329 169.943 82.8968C170.012 84.7733 168.477 86.2914 166.6 86.2848C164.722 86.2782 163.213 84.7497 163.138 82.8734C162.436 65.3393 155.855 48.5458 144.456 35.2042C143.236 33.7765 143.305 31.6296 144.678 30.3489Z"
        fill="currentColor"
      />
      <path
        d="M87.8478 3.44971C87.9134 1.57308 89.4892 0.0978545 91.3617 0.238337C110.584 1.6805 128.751 9.61727 142.87 22.7417C144.245 24.0201 144.233 26.1787 142.901 27.5018C141.569 28.825 139.421 28.8113 138.041 27.5376C125.147 15.6349 108.618 8.41372 91.1238 7.04008C89.2518 6.89309 87.7823 5.32633 87.8478 3.44971Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ListItemSkeleton = () => {
  return (
    <div className={`flex gap-4 items-center py-2 px-6`}>
      <div className="shrink-0">
        <SkeletonBlock width={44} height={44} radius={15} />
      </div>
      <div className="flex flex-col grow gap-1">
        <SkeletonBlock width={"70%"} height={24} radius={10} />
        <SkeletonBlock width={"60%"} height={20} radius={10} />
      </div>
      <Forward fill="gray30" width={16} height={16} />
    </div>
  );
};
export const DividerButtonSkeleton = () => {
  return (
    <div className="flex flex-col ">
      <div className="w-full bg-gray5 h-[1px]"></div>
      <div className="flex justify-center items-center py-[14px]">
        <SkeletonBlock width={123} height={26} radius={10} />
      </div>
      <DividerLine size="md" />
    </div>
  );
};

export const AiSectionSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 mb-4 w-full">
      <div className="pt-6 px-6 flex justify-between items-center">
        <SkeletonBlock width={80} height={28} radius={10} />
        <SkeletonBlock width={86} height={24} radius={10} />
      </div>
      <div className="py-2 flex flex-col gap-2 ">
        {Array.from({ length: 3 }).map((_, index) => (
          <ListItemSkeleton key={index} />
        ))}
      </div>
    </div>
  );
};

export const PerComparisonSkeleton = () => {
  return <SkeletonBlock width={145} height={20} radius={10} />;
};

export const PriceForecastSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="px-6 pt-6 flex flex-col gap-2">
        <div className="flex justify-between items-center">
          <SkeletonBlock width={74} height={28} radius={10} />
          <TargetDateSkeleton />
        </div>
        <SkeletonBlock width={"100%"} height={20} radius={10} />
      </div>
      <div className="px-6 py-4">
        <SkeletonBlock width={"100%"} height={164} radius={10} />
      </div>
      <div className="flex flex-col gap-2 py-2">
        <div className="px-6 py-2 flex items-center justify-between">
          <SkeletonBlock width={86} height={24} />
          <SkeletonBlock width={59} height={46} />
        </div>
        <div className="px-6 py-2 flex items-center justify-between">
          <SkeletonBlock width={86} height={24} />
          <SkeletonBlock width={59} height={46} />
        </div>
      </div>
      <DividerButtonSkeleton />
    </div>
  );
};

export const TrendingSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="px-6 pt-6 flex flex-col gap-2 ">
        <SkeletonBlock width={74} height={28} radius={10} />
        <SkeletonBlock width={298} height={20} radius={10} />
      </div>
      <div className="flex py-4 px-[30px] items-center  justify-between md-1:justify-start md-1:gap-[48px]">
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            className="flex flex-col gap-[10px] items-center md-1:grow"
            key={index}
          >
            <SkeletonBlock width={27} height={20} radius={10} />
            <SkeletonBlock width={42} height={130} radius={10} />
            <SkeletonBlock width={27} height={20} radius={10} />
          </div>
        ))}
      </div>
      <DividerButtonSkeleton />
    </div>
  );
};

export const IndustrySkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-6">
        <div className="px-6 pt-6 flex items-center justify-between">
          <SkeletonBlock width={174} height={28} radius={10} />
          <SkeletonBlock width={137} height={24} radius={10} />
        </div>
        <div className="px-6">
          <SkeletonBlock width={"100%"} height={40} radius={10} />
        </div>
      </div>
      <div className="px-6 py-4">
        <SkeletonBlock width={"100%"} height={142} radius={10} />
      </div>
      <DividerButtonSkeleton />
    </div>
  );
};

export const PortfolioValuesSkeleton = () => {
  return (
    <div className="flex flex-col gap-1">
      <SkeletonBlock width={137} height={32} radius={10} />
      <SkeletonBlock width={96} height={20} radius={10} />
    </div>
  );
};

export const PortfolioForecastSkeleton = () => {
  return <SkeletonBlock width={110} height={24} radius={10} />;
};

export const PortfolioAssetsSkeleton = () => {
  return <SkeletonBlock width={159} height={24} radius={10} />;
};

export const PortfolioAssetListSkeleton = () => {
  return (
    <div className="flex flex-col py-6">
      <div className="flex flex-col gap-2 py-2">
        <div className="px-6">
          <SkeletonBlock width={204} height={26} radius={10} />
        </div>
        <div className="px-6 py-2 items-center flex gap-3">
          <SkeletonBlock width={40} height={40} radius={"100%"} />
          <div className="grow">
            <SkeletonBlock width={"100%"} height={48} radius={10} />
          </div>
          <SkeletonBlock width={204} height={48} radius={10} />
        </div>
        <div className="px-6 py-2 items-center flex gap-3">
          <SkeletonBlock width={40} height={40} radius={"100%"} />
          <div className="grow">
            <SkeletonBlock width={"100%"} height={48} radius={10} />
          </div>
          <SkeletonBlock width={204} height={48} radius={10} />
        </div>
        <div className="px-6 py-2 items-center flex gap-3">
          <SkeletonBlock width={40} height={40} radius={"100%"} />
          <div className="grow">
            <SkeletonBlock width={"100%"} height={48} radius={10} />
          </div>
          <SkeletonBlock width={204} height={48} radius={10} />
        </div>
        <div className="px-6 py-2 items-center flex gap-3">
          <SkeletonBlock width={40} height={40} radius={"100%"} />
          <div className="grow">
            <SkeletonBlock width={"100%"} height={48} radius={10} />
          </div>
          <SkeletonBlock width={204} height={48} radius={10} />
        </div>
      </div>
    </div>
  );
};

export const CandleChartSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="px-6 pt-6 flex flex-col">
        <SkeletonBlock width={80} height={20} radius={10} />
      </div>
      <div className="px-6 ">
        <SkeletonBlock width={"100%"} height={60} radius={10} />
      </div>
      <div className="p-6 ">
        <SkeletonBlock width={"100%"} height={234} radius={10} />
      </div>
    </div>
  );
};

//////////////////////////////
export const PriceInfoSkeleton = () => {
  return (
    <div className="flex pt-4  px-6 flex-col ">
      <SkeletonBlock width={132} height={100} radius={10} />
    </div>
  );
};

export const DiagnosticSkeleton = () => {
  return (
    <div className="flex flex-col gap-2 py-2">
      <div className="w-full pt-6 px-6  border-b-[1px] border-b-gray10 pb-2">
        <SkeletonBlock width={50} height={20} radius={10} />
      </div>
      {Array.from({ length: 3 }).map((item, index) => (
        <div
          className={` py-3 px-6 flex items-center gap-2 bg-white w-full`}
          key={index}
        >
          <SkeletonBlock width={100} height={26} radius={10} />
        </div>
      ))}
    </div>
  );
};

export const DiagnosticDetailSkeleton = () => {
  return (
    <div>
      <div className="h-14 flex items-center justify-center">
        <SkeletonBlock2 width={132} height={32} radius={10} />
      </div>
      <div className="flex flex-col gap-1 px-6 pt-[30px]">
        <SkeletonBlock2 width={120} height={35} radius={10} />
      </div>
      <div className=" py-[30px] px-6 flex flex-col gap-2 items-center">
        <SkeletonBlock2 width={120} height={120} radius={"100%"} />
        <div className="px-2 py-1">
          <SkeletonBlock2 width={95} height={25} radius={10} />
        </div>
        <SkeletonBlock2 width={180} height={25} radius={10} />
      </div>
      <div className="px-5 py-2">
        <SkeletonBlock2 width={"100%"} height={192} radius={10} />
      </div>
      <div className="px-5 py-2">
        <SkeletonBlock2 width={"100%"} height={192} radius={10} />
      </div>
    </div>
  );
};

export const DetailCvarSkeleton = () => {
  return (
    <div>
      <div className="h-14 flex items-center justify-center">
        <SkeletonBlock width={132} height={32} radius={15} />
      </div>
      <div className="flex flex-col gap-3 px-6 pt-[30px]">
        <SkeletonBlock width={120} height={35} radius={15} />
        <SkeletonBlock width={"100%"} height={192} radius={15} />
      </div>
      <div className="px-5 py-4"></div>
      <div className="px-5 py-2">
        <SkeletonBlock width={"100%"} height={192} radius={15} />
      </div>
    </div>
  );
};

export const HighStockPageSkeleton = () => {
  return (
    <div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1 pt-[35px] px-6">
          <SkeletonBlock width={130} height={25} radius={10} />
          <SkeletonBlock width={180} height={22} radius={10} />
        </div>
        <div className="flex items-center gap-2 px-6 py-3">
          <SkeletonBlock width={180} height={57} radius={10} />
        </div>
        <div className="px-4 pb-4">
          <SkeletonBlock width={"100%"} height={300} radius={10} />
        </div>
      </div>
    </div>
  );
};
