import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import Loading from "components/organisms/m/Loading";
import { Forward } from "components/common/Icons";
import { TYPO } from "styles/Typo";
import DetailChartSVSModal from "components/modals/detailChartSVSModal";
import { useTranslation } from "next-i18next";
import useSVG from "utils/clientAPIs/detailSvg";
import { targetDate } from "../util";
import { useRouter } from "next/router";
import useCandleChart from "utils/clientAPIs/candleChart";
import { CandleChartSkeleton } from "components/skeleton/detail";
import SkeletonBlock from "components/skeleton";
import useIsDetailLoading from "states/detailLoading";

const DynamicChart: React.FC<any> = dynamic(
  () => import("components/templates/common/Detail/DetailChart"),
  {
    loading: () => <CandleChartSkeleton />,
    ssr: false,
  }
) as any;
function Chart({
  detailData,
  svgData,
  delayed = false,
}: {
  detailData: any;
  svgData: any;
  delayed?: boolean;
}) {
  const router = useRouter();

  const [candleChart, isValid] = useCandleChart(detailData?.ITEM_CD_DL);
  const isLoading = useIsDetailLoading();

  const [explainModal, setExplainModal] = useState(false);
  const { t } = useTranslation("detail");

  const [candleChartRender, setCandleChartRender] = useState(
    delayed === true && candleChart?.length === 0 ? false : true
  );
  useEffect(() => {
    if (delayed && !candleChartRender) {
      setTimeout(() => {
        setCandleChartRender(true);
      }, 800);
    }
  }, [delayed]);

  const tem = svgData ? [...svgData] : [];
  const dates = [...tem.reverse()]
    ?.map((item: any) => {
      const date = item.BASE_DT.split("T")[0];
      const year = date.split("-")[0];
      const month = date.split("-")[1];
      const day = date.split("-")[2];
      return router.locale === "ko"
        ? `${year.slice(-2)}년 ${Number(month)}월 ${Number(day)}일`
        : `${year.slice(-2)}.${Number(month)}.${Number(day)}`;
    })
    .join(", ");

  if (isValid || !candleChartRender || isLoading)
    return (
      <main className="h-[450px]">
        <CandleChartSkeleton />
      </main>
    );

  return (
    <main id="chart" className="mb-3 w-full bg-white">
      <div className="flex flex-col gap-4 ">
        <div className="flex px-6 pt-5 gap-4  pb-4 flex-col">
          <p className={`${TYPO.TYPO4_SB} text-gray80 grow`}>{t("charts")}</p>

          {svgData && svgData?.length > 0 && (
            <div
              className="rounded-[16px] bg-gray2 p-3 gap-3 flex items-center cursor-pointer"
              onClick={() => {
                window.dataLayer.push({
                  event: "cDetailRWI",
                });
                setExplainModal(true);
              }}
            >
              <CheckFill />
              <div className="flex flex-col grow">
                <span className={`${TYPO.TYPO7_MD} text-gray70 `}>
                  {t("svgOccured")}
                </span>
                <span className={`${TYPO.TYPO7_RG} text-gray50 `}>{dates}</span>
              </div>
              <Forward width={16} height={16} fill="gray30" />
            </div>
          )}
        </div>
      </div>
      <DynamicChart
        chartData={candleChart}
        cat={detailData.CAT && detailData.CAT}
      />

      {explainModal && (
        <DetailChartSVSModal
          setModal={setExplainModal}
          isModalOpen={explainModal}
        />
      )}
    </main>
  );
}
export const CheckFill = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="10" fill="#F99F01" />
      <path
        d="M10.6598 8.49146C10.5744 7.69479 11.1988 7 12 7C12.8012 7 13.4256 7.69479 13.3402 8.49146L12.8299 13.2543C12.7845 13.6784 12.4265 14 12 14C11.5735 14 11.2155 13.6784 11.1701 13.2543L10.6598 8.49146Z"
        fill="#3B4754"
      />
      <rect x="11" y="15" width="2" height="2" rx="1" fill="#3B4754" />
    </svg>
  );
};
export default Chart;
