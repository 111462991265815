import SkeletonBlock, { SkeletonBlock2 } from ".";

export const StockListSkeleton = () => {
  return (
    <div className="w-full flex flex-col bg-white pb-4">
      <div className={`pt-[30px] pl-6`}>
        <SkeletonBlock width={70} height={30} />
      </div>
      <div className="px-6 py-4 flex items-center gap-4 ">
        <SkeletonBlock width={125} height={46} />
        <SkeletonBlock width={125} height={46} />
      </div>
      <div className="flex flex-col gap-2 py-2">
        <div className="w-full flex justify-end px-6">
          <SkeletonBlock width={80} height={16} />
        </div>
        <div className="px-6 gap-2 flex flex-col ">
          <SkeletonBlock width={"100%"} height={66} />
          <SkeletonBlock width={"100%"} height={66} />
          <SkeletonBlock width={"100%"} height={66} />
        </div>
      </div>
    </div>
  );
};

export const StrategySkeletonDesktop = () => {
  return (
    <div className="w-full">
      <div className="pt-[30px] px-6">
        <SkeletonBlock2 width={200} height={25} />
      </div>
      <div className="py-6 px-5 flex items-center gap-4">
        <SkeletonBlock2 width={295} height={374} />
        <SkeletonBlock2 width={295} height={374} />
      </div>
    </div>
  );
};

export const StrategySkeletonMobile = () => {
  return (
    <div className="w-full">
      <div className="pt-[30px] px-6">
        <SkeletonBlock2 width={200} height={25} />
      </div>
      <div className="py-6 px-5 flex items-center gap-4">
        <SkeletonBlock2 width={295} height={374} />
      </div>
    </div>
  );
};
