import React, { useEffect } from "react";
import InterestTable from "components/organisms/InterestTable";
import useInterest from "utils/clientAPIs/interest";

function Interest() {
  const [
    session,
    update,
    userInfo,
    mutate,
    interestedAssets,
    interestMutate,
    interestValid,
  ] = useInterest();

  useEffect(() => {
    if (userInfo && userInfo.user && userInfo.user.interest) {
      interestMutate();
    }
  }, [userInfo, interestMutate]);

  return (
    <main className="min-w-[360px] h-auto bg-white max-w-[800px] w-screen">
      <InterestTable
        session={session}
        data={interestedAssets}
        isValid={interestValid}
      />
    </main>
  );
}

export default Interest;
