import Image from "next/image";
import Weather from "./weather";
import PriceDown, { priceDownProp } from "./priceDown";
import Level from "./level";
import { ReactNode } from "react";
import Img from "./img";
import PriceUp from "./priceUp";
import { TYPO } from "styles/Typo";
import Text from "./Text";

//number (tf)
//이미지(국가 tf)
//제목 - 자산 이름 - 필수
//가격&상승률(하락률) || 티커

// 하락률 || 날씨(폭등) || 주의

// 맨 오른쪽 아이콘

interface weatherProp {
  WTHR_ENG_NM: string;
  WTHR_ENG_DL: string;
  WTHR_KR_DL: string;
}

interface props {
  HR_ITEM_NM: string;
  src?: string;
  flag?: string;
  item: string;
  num?: number;
  numColor?: string;
  ticker?: string;
  price?: string | 0;
  percent?: string;
  weather?: weatherProp;
  priceDown?: priceDownProp;
  priceUp?: priceDownProp;
  level?: string;
  rightIcon?: any;
  className?: string;
  totalscore?: number;
}
const List = ({
  src,
  flag,
  item,
  num,
  numColor,
  ticker,
  price,
  percent,
  weather,
  priceDown,
  priceUp,
  level,
  HR_ITEM_NM,
  rightIcon,
  className,
  totalscore,
}: props) => {
  return (
    <li
      className={`${
        className && className
      } w-full flex items-center  px-5 py-2  gap-3 bg-white `}
    >
      {num && (
        <div className="relative w-4 h-4 pl-1 pr-[5px] justify-center items-center inline-flex">
          <span
            style={{ transform: "translateX(calc(-50% + 8px))" }}
            className={`absolute top-0 left-[0%] text-center ${
              numColor ? numColor : "text-neutral-700"
            }  text-[15px] font-semibold leading-none`}
          >
            {num}
          </span>
        </div>
      )}
      {src && (
        <Img
          src={src}
          flag={flag}
          HR_ITEM_NM={HR_ITEM_NM}
          totalscore={totalscore}
        />
      )}

      <div
        className={`${
          !ticker && !price && !percent ? "justify-center" : "justify-start"
        }  grow  overflow-hidden whitespace-pre-wrap flex-col  items-start inline-flex `}
      >
        <Text ticker={ticker} item={item} price={price} percent={percent} />
      </div>

      {/** 추가 오른쪽 아이콘 자리 */}
      <div className="flex items-center shrink-0 justify-end">
        {weather && (
          <Weather
            ENG_NM={weather.WTHR_ENG_NM}
            ENG_DL={weather.WTHR_ENG_DL}
            KR_DL={weather.WTHR_KR_DL}
          />
        )}

        {priceDown && (
          <PriceDown
            EXP_CVaRNTS_95={priceDown.EXP_CVaRNTS_95}
            ITEM_CD_DL={priceDown.ITEM_CD_DL}
          />
        )}
        {priceUp && (
          <PriceUp
            EXP_CVaRNTS_95={priceUp.EXP_CVaRNTS_95}
            ITEM_CD_DL={priceUp.ITEM_CD_DL}
          />
        )}
        {level && <Level level={level} />}

        <div>{rightIcon && rightIcon()}</div>
      </div>
    </li>
  );
};

export default List;
